import React, { Fragment, Component } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Launch } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import HotelIcon from '@material-ui/icons/Hotel';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import moment from 'moment';
import { COLORS } from '../../../../utils/Application_Constants';

const styles = (theme) => ({
	expansionPanelSummaryRoot: {
		padding: '8px 20px',
	},
	title: {
		fontWeight: 'bold',
		fontSize: '16px',
		marginBottom: '4px',
		color: COLORS.RAVEN,
	},
	value: {
		fontSize: '14px',
		color: COLORS.RAVEN,
	},
	clickableLink: {
		fontWeight: '500',
		color: COLORS.TRUE_BLUE,
		fontSize: '14px',
		textDecoration: 'none',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
	},
	topRightIcon: {
		position: 'absolute',
		top: '8px',
		right: '16px',
	},
	flightCard: {
		marginTop: '5px',
		backgroundColor: 'white',
		padding: '16px',
		minWidth: '100%',
		borderRadius: '8px',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
		position: 'relative',
	},
	iconClass: {
		verticalAlign: 'middle',
		color: COLORS.TRUE_BLUE,
		fontSize: '36px',
	},
	buttonContainer: {
		display: 'flex',
		gap: '24px',
		marginTop: '8px',
		marginLeft: '16px'
	},
	buttonIcon: {
		marginRight: '8px',
		fontSize: '16px',
		color: COLORS.TRUE_BLUE,
		backgroundColor: COLORS.LIGHT_GRAY,
		borderRadius: '50%',
		padding: '4px',
		boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
	},
	section: {
		marginBottom: '12px',
	},
	column: {
		display: 'flex',
		flexDirection: 'column',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
});

class LodgingV3 extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	mapsSelectorForTravel = (addressInfo) => {
		if (
			navigator.platform.indexOf('iPhone') !== -1 ||
			navigator.platform.indexOf('iPad') !== -1 ||
			navigator.platform.indexOf('iPod') !== -1
		) {
			return `https://maps.apple.com/?q=${this.hotelAddressParser(addressInfo)}}`;
		}
		return `https://google.com/maps/search/?api=1&query=${this.hotelAddressParser(addressInfo)}`;
	};

	hotelAddressParser = (addressInfo) => {
		let encodedAddress = '';
		const addressString = `${addressInfo.propertyAddress1} ${addressInfo.propertyCity} ${addressInfo.propertyState} ${addressInfo.propertyZip}`;
		encodedAddress = encodeURI(addressString);
		return encodedAddress;
	};

	render() {
		const { classes, item } = this.props;

		return (
			<Fragment key={item.tripKey + '-' + item.flightNumber}>
				<Grid container direction="column" className={classes.flightCard}>
					<div className={classes.row}>
						<div className={classes.column}>
							<div className={classes.section}>
								<div className={classes.title}>LODGING</div>
								<div className={classes.value}>{item.vendorName}</div>
							</div>
							<div className={classes.section}>
								<div className={classes.title}>ADDRESS</div>
								<div className={classes.value}>{`${item.propertyAddress1}, ${item.propertyCity}, ${item.propertyState} ${item.propertyZip}`}</div>
							</div>
						</div>
						<div className={classes.topRightIcon}>
							<HotelIcon className={classes.iconClass} />
						</div>
					</div>
					<div className={classes.row}>
						<div className={classes.column}>
							<div className={classes.section}>
								<div className={classes.title}>CHECK-IN</div>
								<div className={classes.value}>{`${moment(item.originatedDate).format('MMM Do h:mm a')}`}</div>
							</div>
						</div>
						<div className={classes.column}>
							<div className={classes.section}>
								<div className={classes.title}>CHECK-OUT</div>
								<div className={classes.value}>{`${moment(item.completedDate).format('MMM Do h:mm a')}`}</div>
							</div>
						</div>
						<div className={classes.column}>
							<div className={classes.section}>
								<div className={classes.title}>CONFIRMATION</div>
								<div className={classes.value}>
									<a
										href={item.eItinLink}
										className={classes.clickableLink}
										target="travel"
										rel="noopener noreferrer"
									>
										<InsertDriveFileOutlinedIcon fontSize='small' />
										{item.confirmationNumber}
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className={classes.buttonContainer}>
						<a
							href={this.mapsSelectorForTravel(item)}
							className={classes.clickableLink}
							target="_blank"
							rel="noopener noreferrer"
						>
							<LocationOnIcon className={classes.buttonIcon} />
							Directions
						</a>
						<a
							href={item.bookingLink}
							className={classes.clickableLink}
							target="_blank"
							rel="noopener noreferrer"
						>
							<Launch className={classes.buttonIcon} />
							Booking
						</a>
						<a
							href={item.downloadLink}
							className={classes.clickableLink}
							download
						>
							<GetAppIcon className={classes.buttonIcon} />
							Download
						</a>
					</div>
				</Grid>
			</Fragment>
		);
	}
}

export default withTranslation()(withStyles(styles)(LodgingV3));
