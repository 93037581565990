/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
    Checkbox,
    FormControlLabel,
    Box,
    FormControl,
    FormGroup,
    Grid,
    Chip,
    Typography,
    withStyles,
    Button,
    Collapse,
} from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Controller, useFormContext } from 'react-hook-form';

const styles = (theme) => ({
    drawer: {
        width: '28em',
        padding: '1em',
        position: 'initial',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            backgroundColor: 'transparent',
        },
        '&:active': {
            backgroundColor: 'transparent',
        },
        borderRadius: '0',
    },
    content: {
        paddingLeft: '1em',
    },
    clear: {
        textDecoration: 'underline',
        paddingLeft: '1em',
    },
    chipRoot: {
        textAlign: 'center',
        width: '13em',

        [theme.breakpoints.up('lg')]: {
            minWidth: '100px',
        },
    },
    chipOnHoldMatchPending: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldPendingAirfare: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldAirlineCreditOnFile: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldDisputed: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldAdvancedDeposit: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldReimbursement: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipReadyForReview: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipInProcess: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipNew: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipApproved: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipMatched: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipAbandoned: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipPosted: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipMissingInformation: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        borderColor: COLORS.LT_PEPPER,
    },
    chipRejected: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        borderColor: COLORS.LT_PEPPER,
    },
    chipAssigned: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipUnassigned: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipTcApproved: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipNeedMoreInformation: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipSubmitted: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    border: {
        borderBottom: '1px solid',
    },
    checkboxLabel: {
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
    },
    expenseTypeHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHTSTEEL}`,
        paddingBottom: '4px',
        marginBottom: '8px',
        marginTop: '10px',
    },
});

const ExpenseFilterCheckbox = (props) => {
    const { classes, filterName, list, isExpenseType } = props;
    const { control, watch, setValue, getValues } = useFormContext();
    const watchedValue = watch(filterName);
    const [expanded, setExpanded] = useState(false);

    const renderStatusChip = (item) => {
        const name = item.UI.replace(/ /g, '');
        const status = item.value;
        return (
            <Chip
                label={name}
                className={classes[`chip${status}`]}
                classes={{ root: classes.chipRoot }}
                title={name}
            />
        );
    };

    const filterList = () => {
        return (
            <FormControl component='fieldset'>
                <FormGroup>
                    <Controller
                        name={filterName}
                        control={control}
                        defaultValue={[]}
                        render={({ field, onChange, value }) => {
                            return filterName === 'Statuses' ? (
                                <>
                                    {list?.map((item) => {
                                        return (
                                            <FormControlLabel
                                                key={item.UI}
                                                control={
                                                    <Checkbox
                                                        checked={value.includes(item.UI)}
                                                        onChange={(e) =>
                                                            onChange(
                                                                e.target.checked
                                                                    ? [...value, item.UI]
                                                                    : value.filter(
                                                                        (s) => s !== item.UI
                                                                    )
                                                            )
                                                        }
                                                        {...field}
                                                        value={item.UI}
                                                        color='primary'
                                                    />
                                                }
                                                classes={{ label: classes.checkboxLabel }}
                                                label={renderStatusChip(item)}
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {list?.map((item, index) => {
                                        return (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={value.includes(item)}
                                                        onChange={(e) =>
                                                            onChange(
                                                                e.target.checked
                                                                    ? [...value, item]
                                                                    : value.filter(
                                                                        (s) => s !== item
                                                                    )
                                                            )
                                                        }
                                                        {...field}
                                                        value={item}
                                                        color='primary'
                                                    />
                                                }
                                                classes={{ label: classes.checkboxLabel }}
                                                label={item}
                                            />
                                        );
                                    })}
                                </>
                            );
                        }}
                    />
                </FormGroup>
            </FormControl>
        );
    };

    const expenseTypeList = () => {
        return (
            <FormControl component='fieldset'>
                <FormGroup>
                    <Controller
                        name={filterName}
                        control={control}
                        defaultValue={[]}
                        render={({ field, onChange, value }) => {
                            return (
                                <>
                                    {list?.map((category, catIndex) => {
                                        const categoryName = category.category;
                                        const type = category.types;
                                        return (
                                            <Grid key={`category-${catIndex}-${categoryName}`}>
                                                <Typography className={classes.expenseTypeHeader}>
                                                    {categoryName}
                                                </Typography>
                                                {type.map((typeName, typeIndex) => {
                                                    return (
                                                        <React.Fragment
                                                            key={`type-${catIndex}-${typeName.description}-${typeIndex}`}
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={value.includes(
                                                                            typeName.description
                                                                        )}
                                                                        onChange={(e) =>
                                                                            onChange(
                                                                                e.target.checked
                                                                                    ? [
                                                                                        ...value,
                                                                                        typeName.description,
                                                                                    ]
                                                                                    : value.filter(
                                                                                        (s) =>
                                                                                            s !==
                                                                                            typeName.description
                                                                                    )
                                                                            )
                                                                        }
                                                                        {...field}
                                                                        value={typeName.description}
                                                                        color='primary'
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.checkboxLabel,
                                                                }}
                                                                label={typeName.description}
                                                            />
                                                            <Box component='div' m={1} />
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </Grid>
                                        );
                                    })}
                                </>
                            );
                        }}
                    />
                </FormGroup>
            </FormControl>
        );
    };

    const renderAccordion = () => {
        return (
            <>
                <Button
                    className={classes.drawer}
                    style={
                        !expanded
                            ? { borderBottom: `1px solid ${COLORS.LT_MIDNIGHTSTEEL}` }
                            : { borderBottom: 'none' }
                    }
                    disableRipple
                    onClick={() => setExpanded(!expanded)}
                    aria-expanded={expanded}
                    aria-controls='panel1-content'
                    endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                    {expanded ? (
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                                <Typography>
                                    {filterName} ({watchedValue?.length ? watchedValue?.length : 0})
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant='body2'
                                    className={classes.clear}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setValue(filterName, []);
                                    }}
                                >
                                    Clear
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container alignItems='center' spacing={1}>
                            <Grid item>
                                <Typography>
                                    {filterName} ({watchedValue?.length ? watchedValue?.length : 0})
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Button>
                <Collapse in={expanded} className={classes.content}>
                    {!isExpenseType ? filterList() : expenseTypeList()}
                </Collapse>
            </>
        );
    };

    return <>{renderAccordion()}</>;
};

export default withStyles(styles)(ExpenseFilterCheckbox);
