import { Box, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	status: {
		width: 12,
		height: 12,
		borderRadius: 100,
		borderWidth: 1,
		borderStyle: 'solid',
		background: COLORS.LT_MIDNIGHT10,
		borderColor: COLORS.LT_MIDNIGHT50,
	},
	optionPrimary: {
		background: COLORS.LT_JOURNEY10,
		borderColor: COLORS.LT_JOURNEY,
	},
	optionSuccess: {
		background: COLORS.LT_EMERALD10,
		borderColor: COLORS.LT_EMERALD,
	},
	optionError: {
		background: COLORS.LT_PEPPER10,
		borderColor: COLORS.LT_PEPPER,
	},
	optionWarning: {
		background: COLORS.LT_SUNFLOWER10,
		borderColor: '#AD7829',
    },
    chipOnHoldMatchPending: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldPendingAirfare: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldAirlineCreditOnFile: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldDisputed: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldAdvancedDeposit: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipOnHoldReimbursement: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipReadyForReview: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipInProcess: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipNew: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipApproved: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipMatched: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipAbandoned: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipPosted: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipMissingInformation: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        borderColor: COLORS.LT_PEPPER,
    },
    chipRejected: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        borderColor: COLORS.LT_PEPPER,
    },
    chipAssigned: {
        backgroundColor: COLORS.LT_EMERALD10,
        color: COLORS.LT_EMERALD,
        borderColor: COLORS.LT_EMERALD,
    },
    chipUnassigned: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipTcApproved: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    },
    chipNeedMoreInformation: {
        backgroundColor: COLORS.LT_SUNFLOWER25,
        color: '#AD7829',
        borderColor: '#AD7829',
    },
    chipSubmitted: {
        backgroundColor: COLORS.LT_JOURNEY10,
        color: COLORS.LT_JOURNEY,
        borderColor: COLORS.LT_JOURNEY,
    }
}));

const StatusOption = (props) => {
	const { status, label, t } = props;
	const classes = useStyles();
	return (
		<Box display={'flex'} gridGap={8} alignItems='center'>
            <div
                className={`${classes.status} ${classes[`chip${status}`]}`}
			></div>
            <div>{label}</div>
		</Box>
	);
};

export default withTranslation()(StatusOption);
