/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import { axiosWCApiInstance } from '../Configurations/AxiosConfiguration.js';
import { ExpenseStatusEnum } from '../Components/Expense/ExpenseStatus.enum.js';
import { ExpenseBillTypeEnum } from '../Components/Expense/BillType.enum.js';
import { ExpenseSourceEnum } from '../Components/Expense/ExpenseSource.enum.js';
import { PreCodedStatusEnum } from '../Components/Expense/PreCodedStatus.enum.js';

export const getClinicianAssignments = async (bookingId) => {
    try {
        const url = `assignments/${bookingId}`;
        const response = await axiosWCApiInstance.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getClinician = async (clinicianId) => {
    try {
        const url = `clinicians/${clinicianId}`;
        const response = await axiosWCApiInstance.get(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const createExpense = async (expense) => {
    try {
        const response = await axiosWCApiInstance.post('/expenses', expense);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updateExpense = async (key, expense) => {
    try {
        const response = await axiosWCApiInstance.put(`/expenses/${key}`, expense);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getExpenseImageById = async (expenseId, imageId, isPrecoded) => {
    try {
        const response = await axiosWCApiInstance.get(`/expenses/${expenseId}/images/${imageId}?isPrecoded=${isPrecoded}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getExpenseById = async (expenseId) => {
    try {
        const response = await axiosWCApiInstance.get(`/expenses/${expenseId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getExpenses = async () => {
    try {
        const response = await axiosWCApiInstance.get('/administration/expenses');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getPendingCCExpenseById = async (expenseId) => {
    try {
        const response = await axiosWCApiInstance.get(`/expenses/precoded/${expenseId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const createPendingCCExpense = async (expense) => {
    try {
        const response = await axiosWCApiInstance.post('/expenses/precoded', expense);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const updatePendingCCExpense = async (key, expense) => {
    try {
        const response = await axiosWCApiInstance.put(`/expenses/precoded/${key}`, expense);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const createExpenseImage = async (imgData) => {
    try {
        const response = await axiosWCApiInstance.post('/expenses/images', imgData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const uploadExpensesFile = async (formData) => {
    try {
        const response = await axiosWCApiInstance.post('/expenses/upload', formData);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getAllCreditCards = async () => {
    try {
        const response = await axiosWCApiInstance.get('/administration/CreditCards');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getDivisions = async (includeInactive, includeSGA) => {
    try {
        let aIncludeInactive = includeInactive;
        if (!(aIncludeInactive === false || aIncludeInactive === true)) {
            aIncludeInactive = false;
        }
        let aincludeSGA = includeSGA;
        if (!(aincludeSGA === false || aincludeSGA === true)) {
            aincludeSGA = false;
        }

        const response = await axiosWCApiInstance.get(`/divisions/?includeInactive=${aIncludeInactive}&includeSGA=${aincludeSGA}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getStatusesAllowed = async (expClass) => {
    try {
        const response = await axiosWCApiInstance.get(`/expenses/statuses?expClass=${expClass}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const getNeedsReviewReasons = async () => {
    try {
        const response = await axiosWCApiInstance.get('/expenses/errorCodes');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getExpenseCategories = async (expenseTypeSelection) => {
    try {
        let aExpenseTypeSelection = expenseTypeSelection;
        if (!aExpenseTypeSelection) {
            aExpenseTypeSelection = 'All';
        }
        const response = await axiosWCApiInstance.get(`expenseTypes?expenseTypeSelection=${aExpenseTypeSelection}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const getKeyFromValueStatus = (value) => {
    for (const index in ExpenseStatusEnum) {
        const statusEnum = ExpenseStatusEnum[index].UI;
        if (statusEnum === value) {
            return ExpenseStatusEnum[index].value;
        }
    }
    //if nothing is found in ExpenseStatusEnum, check PreCodedStatusEnum
    for (const index in PreCodedStatusEnum) {
        const statusEnum = PreCodedStatusEnum[index].UI;
        if (statusEnum === value) {
            return PreCodedStatusEnum[index].value;
        }
    }
};

const getKeyFromValue = (value, type) => {
    const noSpace = value.replace(' ', '');
    for (const index in type) {
        const statusEnum = type[index];
        if (statusEnum === value) {
            return index;
        }
    }
};


const getUrlParams = ({
    offset,
    sortBy,
    sortDirection,
    limit,
    statuses,
    divisions,
    transactionStartDate,
    transactionEndDate,
    expenseTypes,
    sources,
    needsReviewReasons,
    billTypes,
    startStartDate,
    startEndDate,
    endStartDate,
    endEndDate,
    postStartDate,
    postEndDate,
    periodEndStart,
    periodEndEnd,
    merchantStates,
    search,
    searchKey,
}) => {
    const offsetParam = `Offset=${offset}`;
    const pageSizeParam = `&PageSize=${limit}`;
    const statusParams = statuses
        ?.map((status) => `&Statuses=${getKeyFromValueStatus(status)}`)
        .join('');
    const divisionParams = divisions?.map((division) => `&Divisions=${division}`).join('');
    const expenseTypeParams = expenseTypes
        ?.map((expenseType) => `&ExpenseType=${expenseType}`)
        .join('');
    const sourceParams = sources
        ?.map((source) => `&Source=${getKeyFromValue(source, ExpenseSourceEnum)}`)
        .join('');
    const needsReviewParams = needsReviewReasons
        ?.map((reason) => `&NeedsReviewReason=${reason.toUpperCase().replace(/ /g, '_')}`)
        .join('');
    const billTypeParams = billTypes
        ?.map((billType) => `&BillType=${getKeyFromValue(billType, ExpenseBillTypeEnum)}`)
        .join('');
    const merchantStateParams = merchantStates
        ?.map((merchantState) => `&MerchantState=${merchantState}`)
        .join('');
    const sortParams = sortBy ? `&OrderBy=${sortBy}&Dir=${sortDirection}` : '';
    const transactionDateStartParam = transactionStartDate
        ? `&TransactionDateStart=${transactionStartDate}`
        : '';
    const transactionDateEndParam = transactionEndDate
        ? `&TransactionDateEnd=${transactionEndDate}`
        : '';
    const startDateStartParam = startStartDate ? `&StartDateStart=${startStartDate}` : '';
    const startDateEndParam = startEndDate ? `&StartDateEnd=${startEndDate}` : '';
    const endDateStartParam = endStartDate ? `&EndDateStart=${endStartDate}` : '';
    const endDateEndParam = endEndDate ? `&EndDateEnd=${endEndDate}` : '';
    const postDateStartParam = postStartDate ? `&PostDateStart=${postStartDate}` : '';
    const postDateEndParam = postEndDate ? `&PostDateEnd=${postEndDate}` : '';
    const periodEndDateStartParam = periodEndStart ? `&PeriodEndDateStart=${periodEndStart}` : '';
    const periodEndDateEndParam = periodEndEnd ? `&PeriodEndDateEnd=${periodEndEnd}` : '';
    return {
        offsetParam,
        pageSizeParam,
        statusParams,
        divisionParams,
        expenseTypeParams,
        sourceParams,
        needsReviewParams,
        billTypeParams,
        merchantStateParams,
        sortParams,
        transactionDateStartParam,
        transactionDateEndParam,
        startDateStartParam,
        startDateEndParam,
        endDateStartParam,
        endDateEndParam,
        postDateStartParam,
        postDateEndParam,
        periodEndDateStartParam,
        periodEndDateEndParam
    };
};

export const getExpensesWithFilter = async (request) => {
    const {
        offsetParam,
        pageSizeParam,
        statusParams,
        divisionParams,
        expenseTypeParams,
        sourceParams,
        needsReviewParams,
        billTypeParams,
        merchantStateParams,
        sortParams,
        transactionDateStartParam,
        transactionDateEndParam,
        startDateStartParam,
        startDateEndParam,
        endDateStartParam,
        endDateEndParam,
        postDateStartParam,
        postDateEndParam,
        periodEndDateStartParam,
        periodEndDateEndParam
    } = getUrlParams(request);

    const url =
        'administration/expenses?' +
        (offsetParam ? `${offsetParam}` : '') +
        (pageSizeParam ? `${pageSizeParam}` : '') +
        (statusParams ? `${statusParams}` : '') +
        (divisionParams ? `${divisionParams}` : '') +
        (expenseTypeParams ? `${expenseTypeParams}` : '') +
        (sourceParams ? `${sourceParams}` : '') +
        (needsReviewParams ? `${needsReviewParams}` : '') +
        (billTypeParams ? `${billTypeParams}` : '') +
        (merchantStateParams ? `${merchantStateParams}` : '') +
        (sortParams ? `${sortParams}` : '') +
        (transactionDateStartParam ? `${transactionDateStartParam}` : '') +
        (transactionDateEndParam ? `${transactionDateEndParam}` : '') +
        (startDateStartParam ? `${startDateStartParam}` : '') +
        (startDateEndParam ? `${startDateEndParam}` : '') +
        (endDateStartParam ? `${endDateStartParam}` : '') +
        (endDateEndParam ? `${endDateEndParam}` : '') +
        (postDateStartParam ? `${postDateStartParam}` : '') +
        (postDateEndParam ? `${postDateEndParam}` : '') +
        (periodEndDateStartParam ? `${periodEndDateStartParam}` : '') +
        (periodEndDateEndParam ? `${periodEndDateEndParam}` : '');
    const response = await axiosWCApiInstance.get(url.endsWith('&') ? url.slice(0, -1) : url);
    return response.data;
};

export const getPreCodedWithFilter = async (request) => {
    const {
        offsetParam,
        pageSizeParam,
        statusParams,
        divisionParams,
        expenseTypeParams,
        billTypeParams,
        sortParams,
        transactionDateStartParam,
        transactionDateEndParam,
        startDateStartParam,
        startDateEndParam,
        endDateStartParam,
        endDateEndParam,
    } = getUrlParams(request);

    const url =
        'administration/precoded?' +
        (offsetParam ? `${offsetParam}` : '') +
        (pageSizeParam ? `${pageSizeParam}` : '') +
        (statusParams ? `${statusParams}` : '') +
        (divisionParams ? `${divisionParams}` : '') +
        (expenseTypeParams ? `${expenseTypeParams}` : '') +
        (billTypeParams ? `${billTypeParams}` : '') +
        (sortParams ? `${sortParams}` : '') +
        (transactionDateStartParam ? `${transactionDateStartParam}` : '') +
        (transactionDateEndParam ? `${transactionDateEndParam}` : '') +
        (startDateStartParam ? `${startDateStartParam}` : '') +
        (startDateEndParam ? `${startDateEndParam}` : '') +
        (endDateStartParam ? `${endDateStartParam}` : '') +
        (endDateEndParam ? `${endDateEndParam}` : '');
    const response = await axiosWCApiInstance.get(url.endsWith('&') ? url.slice(0, -1) : url);
    return response.data;
};