import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ExpenseTextField from './ExpenseTextField';
import AmountCurrencyInputAdornment from './AmountCurrencyInputAdornment';
import { valueAsExpense } from '../../Utils/ValueAsUtils';
import { ExpenseStatusValuesEnum } from './ExpenseStatus.enum';

const ExpenseAmount = (props) => {
	const { disabled, index, expense, isReimbursement, mode, isPosted } = props;
	const { control, errors, watch } = useFormContext();
	const expenseEntries = watch('expenseEntries');
	const [required, setRequired] = useState({ required: true });

	useEffect(() => {
		setRequired({ required: (mode !== 'cc' || expense?.status === ExpenseStatusValuesEnum.Approved) });
	}, [expense?.status]);

	return (
		<Grid item xs={12} md={6}>
			<Controller
				control={control}
				name={`expenseEntries[${index}].amount`}
				defaultValue={expense.amount}
				rules={{
					required: required.required ? 'Expense amount is required' : false,
				}}
				render={({ onChange, value }) => (
					<ExpenseTextField
						{...required}
						placeholder='0.00'
						id={`expense-amount`}
						onChange={(e) => onChange(valueAsExpense(e.target.value))}
						value={value}
						label='Submitted expense amount'
						endAdornment={
							<AmountCurrencyInputAdornment
								disabled={disabled || isPosted || (!!expense.id && !isReimbursement)}
								index={index}
								currencyType={expense.currency}
							/>
						}
						error={Boolean(errors.expenseEntries?.[index]?.amount)}
						disabled={isPosted}
						helperText={errors.expenseEntries?.[index]?.amount?.message}
					/>
				)}
			/>
		</Grid>
	);
};

export default ExpenseAmount;
